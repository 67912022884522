import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobileNavVisible: false
    }
  }

  handleNavClose = () => {
    document.getElementsByTagName("html")[0].style.overflow = 'auto';
    document.getElementsByTagName("body")[0].style.overflow = 'auto';
    this.setState({ isMobileNavVisible: false });
  }

  handleNavOpen = () => {
    document.getElementsByTagName("html")[0].style.overflow = 'hidden';
    document.getElementsByTagName("body")[0].style.overflow = 'hidden';
    this.setState({ isMobileNavVisible: true });
  }
  render() {
    const { children, className, showBigCircle = false } = this.props;
    return (
    <StaticQuery
      query={graphql`
        query PageLayout {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <div className={`page page-viewport ${className || ""}`}>

          {children}
        </div>
      )}
    />)
  }
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Page
