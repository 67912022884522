import React from 'react';

const HeroTitle = ({ textArray, ...props }) => (
  <h1 className={`hero-title d-inline-flex flex-column ${props.className}`}>
    {
      textArray.map(
        text => (
          <div>
            <span className='text-nowrap d-inline-block bg-base2 bg-tail p-2 font-weight-verybold font-1 font-md-3 font-italic font-sans-serif'>{ text }</span>
          </div>
        )
      )
    }
  </h1>
);


export default HeroTitle;