import React from 'react';

const FullPageSection = (props) => (
  <section 
    className={`w-100 ${props.half ? 'vh-60' : 'vh-100'} bg-cover ${props.className} full-page-section`}
    style={{
      backgroundImage: props.bgImage && `url(${props.bgImage})`,
      backgroundPosition: props.bgPosition || 'center'
    }}
  >
    <div className='z-top position-relative w-100'>
      {props.children}
    </div>
  </section>
);

export default FullPageSection;